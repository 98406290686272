<template>
    <section class="flex-1 overflow-auto">
        <SubHeader :backButton="true" backRedirectionUrl="/case-dashboard" />
        <!-- Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem veniam molestiae voluptatem sint assumenda doloribus culpa numquam corporis, non voluptas sit eius suscipit necessitatibus ex! Temporibus quaerat quos corporis facere. -->
        <!-- <header
      class="pb-0 mb-4 border-b flex justify-between flex-row-reverse items-center"
    >
      <button
        class="btn px-0 mr-0 normal-case btn-link"
        @click="$router.push({ name: 'dashboard.view' })"
      >
        Go Back to Dashboard
      </button>
      <div class="text-xl text-gray-700 font-bold">Pending Activities</div>
    </header> -->
        <table-large
            :columns="columns"
            :rows="rows"
            sortingEnabled
            @sort-change="$emit('on-sort-change', $event)"
            :isLoading="loadingStates && loadingStates.activity"
            @per-page-change="$emit('onPerPageChange', $event)"
            @page-change="$emit('onPageChange', $event)"
            :pagination-options="paginationOptions"
            :totalRows="totalRows"
            :filterOptions="filterOptions"
            :filters="columnFilters"
            :filterOptionsAsync="filterOptionsAsync"
            @column-filter="$emit('onColumnFilters', $event)"
            @search-filter="$emit('getSearchOptionsTick', $event)"
            @filters-clear="$emit('clearFilters')"
            @on-allocation="$emit('on-allocation', $event)"
            :due_date="due_date"
        >
            <template #link="{ customData }">
                <!-- <router-link
                    v-if="customData.case_task_object_type && customData.case_task_object_type === `additional_form`"
                    :to="{
                        name: 'Profile Container',
                        params: { caseid: customData.visible_case_id },
                        query: { caseid: customData.case_id, additional_form_id: customData.case_task_object_id },
                        hash: `#${customData.object_id}`,
                    }"
                    class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
                    >{{ customData.visible_case_id }}</router-link
                > -->
                <div @click="handleCaseClick(customData)" class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus">
                    {{ customData.visible_case_id }}
                </div>
                <!-- <router-link
                    v-else
                    :to="{
                        name: 'Profile Container',
                        params: { caseid: customData.visible_case_id },
                        query: { caseid: customData.case_id, action: 'history' },
                        hash: `#${customData.object_id}`,
                    }"
                    class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
                    >{{ customData.visible_case_id }}</router-link
                > -->
            </template>
            <template #rich_text="customData">
                {{ checkData(customData) }}
            </template>
        </table-large>
        <!-- // ROWS  -->
    </section>
</template>

<script>
import tableLarge from "@shared/dashboard-components/table-large";
import SubHeader from "@/components/SubHeader";

export default {
    name: "Pending-Activity",
    data() {
        return {
            // columnFilters: {}
        };
    },
    props: {
        due_date:{
            type: String,
            default: ()=> "Select date"
        },
        columns: {
            type: Array,
            default: () => [],
        },
        columnFilters: {
            type: Object,
            default: () => {},
        },
        rows: {
            type: Array,
            default: () => [],
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
        paginationOptions: {
            type: Object,
            default: () => {},
        },
        totalRows: {
            type: Number,
            default: 1,
        },
        filterOptions: {
            type: Object,
        },
        filterOptionsAsync: {
            type: Object,
        },
    },
    components: {
        tableLarge,
        SubHeader,
    },
    async mounted() {},
    methods: {
        removeHTML(str) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = str;
            return tmp.textContent || tmp.innerText || "";
        },
        checkData(value) {
            let item = value.customData;
            if (item?.activity_type?.value === "case_task" || item?.activity_type?.value === "email") {
                return this.removeHTML(item.title.label);
            } else {
                return item.title.label;
            }
        },
        clearFilters() {
            this.filterOptions = null;
        },
        handleCaseClick(customData) {
            let route = null;
            if (customData.case_task_object_type && customData.case_task_object_type === `additional_form`) {
                route = this.$router.resolve({
                    name: "Profile Container",
                    params: { caseid: customData.visible_case_id },
                    query: { caseid: customData.case_id, additional_form_id: customData.case_task_object_id, tabType: customData?.case_task_object_type },
                    hash: `#${customData.object_id}`,
                });
            } else {
                route = this.$router.resolve({
                    name: "Profile Container",
                    params: { caseid: customData.visible_case_id },
                    query: { caseid: customData.case_id, action: "history" },
                    hash: `#${customData.object_id}`,
                });
            }
            if (route) window.open(route.href);
        },
    },
};
</script>

<style></style>
